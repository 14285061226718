import React from 'react';
import useInputState from '../hooks/useInputState';
// import captureClientEmail from '../../functions/firebase-cloud-functions';

// import '../styles/EmailCapture.scss';

/**
 * In order for this signup form to work, go into MailChimp and create
 * a new embedded Sign Up Form for the desired audience. 
 * 
 * Under where it says "Copy/paste onto your site", copy the action URL
 * and paste it into the action variable below under handleSubmit.
 *
 * You may have to edit the Audience in MailChimp and add new Merge Fields
 * for COUNTY, PARCELNUM, and FSOURCE for the code below to work properly.
 */

function EmailCapture(props) {
  const [ userEmail, handleEmailChange ] = useInputState('');
  const [ userName, handleNameChange ] = useInputState('');
  const [ userPhone, handlePhoneChange ] = useInputState('');
  const [ userCounty, handleCountyChange ] = useInputState('');
  const [ userParcelNumber, handleParcelNumberChange ] = useInputState('');

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  };

  // const resetAllInputFields = () => {
  //   resetEmail();
  //   resetName();
  //   resetPhone();
  //   resetCounty();
  //   resetParcelNumber();
  // };

  const redirectToGoogleForm = () => {
    const googleFormUrl = 'https://docs.google.com/forms/d/e/1FAIpQLSdqStr3N9HFvRfjWCykht37G9ffDnZVplCO3juzmieSw-NOug/viewform?usp=sf_link';
    window.location = googleFormUrl;
  };

  const handleSubmit = (e) => {    
    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ 
          "form-name": "contact", 
          user_email: userEmail, 
          user_name: userName, 
          user_phone: userPhone, 
          user_county: userCounty, 
          user_parcel_number: userParcelNumber,
        })
      })
      .then(() => {
        console.log('Submission successful!');
        redirectToGoogleForm();
      })
      .catch(error => alert(error))

      e.preventDefault();
  };

  // const redirectToGoogleForm = () => {
  //   const googleFormUrl = 'https://forms.gle/CujGFVYfruQnuQLw6';
  //   window.location = googleFormUrl;
  // };

  return (
    <>
      <div className='capture columns'>
        <div className='column capture-features is-7-tablet is-8-desktop'>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  Get a Fair Cash Offer
                </h2>
              </div>
            </div>
          </article>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  We Will Buy Land in Any Condition 
                </h2>
              </div>
            </div>
          </article>
          <article className='media'>
            <figure className='media-left'>
              <div className='image'>
                <img src='https://landleads.reiconversion.com/wp-content/uploads/elementor/thumbs/sell-your-land-property-point-icon-o8pbzy90kckyn9a1sg4o73knb2vf5mglkh10jbx5wy.png' alt='Ribbon Icon' />
              </div>
            </figure>
            <div className='media-content'>
              <div className='content'>
                <h2 className='subtitle is-size-4 has-text-primary'>
                  We'll Take Care of Paperwork and Closing
                </h2>
              </div>
            </div>
          </article>

          <div className='start-here'>
            <h3>Start here by filling out this form!</h3>
            <span className='icon email-capture-arrow'>
              <i className='fas fa-long-arrow-alt-right' />
            </span>
          </div>
        </div>
        <div className='column capture-form has-text-left'>
          <h2>Start Here</h2>
          <p>To Get Your Cash Offer!</p>

          <form id="contact-form" className='field-group' onSubmit={handleSubmit}>
            <input type="hidden" name="form-name" value="contact" />

            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='user_name' required value={userName} onChange={handleNameChange} placeholder='Full Name' />
                <span className='icon is-left'>
                  <i className='fas fa-user' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='user_phone' required value={userPhone} onChange={handlePhoneChange} placeholder='Phone Number' />
                <span className='icon is-left'>
                  <i className='fas fa-phone-alt' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='user_email' required value={userEmail} onChange={handleEmailChange} placeholder='Email Address' />
                <span className='icon is-left'>
                  <i className='fas fa-envelope' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='user_county' required value={userCounty} onChange={handleCountyChange} placeholder="County" />
                <span className='icon is-left'>
                  <i className='fas fa-tree' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control has-icons-left'>
                <input className='input is-medium' type='text' name='user_parcel_number' required value={userParcelNumber} onChange={handleParcelNumberChange} placeholder="Parcel Number" />
                <span className='icon is-left'>
                  <i className='fas fa-map-marked-alt' />
                </span>
              </div>
            </div>
            <div className='field'>
              <div className='control'>
                <button type='submit' className='button capture-button is-large is-fullwidth'>Get Your Cash Offer</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default EmailCapture;
