import React from 'react';
// import '../styles/HowItWorks.scss';

function HowItWorks() {
  return (
    <section className='how-it-works'>
      <div className='container'>
        <h1 className='title has-text-primary is-size-1-tablet has-text-centered'>- How It Works -</h1>
        <div className='step'>
          <h2 className='subtitle has-text-primary'>1. Fill out our form.</h2>
          <div className='columns is-centered'>
            <div className='column is-3 step-img'>
              <img src='https://landleads.reiconversion.com/wp-content/uploads/2019/06/sell-your-land-property-how-it-works-fill-out-the-form-gray.png' alt='Contract Icon' />
            </div>
            <div className='column step-content'>
              <p>
                <span>Submit your property via the form on our page or give us a call. </span>
                 We will take a look at county information, recent sales in the area, and comparable properties to determine a fair offer for your property. 
              </p>
            </div>
          </div>
        </div>
        <div className='step'>
          <h2 className='subtitle has-text-primary'>2. We send you an offer.</h2>
          <div className='columns is-centered'>
            <div className='column is-3 step-img'>
              <img src='https://landleads.reiconversion.com/wp-content/uploads/2019/06/sell-your-land-property-how-it-works-get-a-cash-offer-gray.png' alt='Dollar Icon' />
            </div>
            <div className='column step-content'>
              <p>
                <span>We will contact you </span>
                 by phone or email to discuss our offer with you. Once we have reached an agreement, we’ll prepare a purchase agreement and send it to you to sign. 
              </p>
            </div>
          </div>
        </div>
        <div className='step'>
          <h2 className='subtitle has-text-primary'>3. You’ve sold your land!</h2>
          <div className='columns is-centered'>
            <div className='column is-3 step-img'>
              <img src='https://landleads.reiconversion.com/wp-content/uploads/2019/06/sell-your-land-property-how-it-works-get-paid-gray.png' alt='Bank Icon' />
            </div>
            <div className='column step-content'>
              <p>
                Once we receive your signed agreement we will begin the closing process and prepare a deed of transfer. 
                <span> Payment will be sent to you by check or bank account transfer. </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks;
