import React from 'react';

function Notification(props) {
  const hideNotification = () => {
    props.setIsNotificationShowing(false);
  }

  return (
    <aside className={`notification is-success ${!props.isNotificationShowing && 'is-hidden'}`}>
      <button className='delete' onClick={hideNotification}></button>
      Success! We received your submission and will contact you soon.
    </aside>
  )
}

export default Notification;
