import React from 'react';

import Logo from '../images/logo.png';
import businessInfo from '../site.config';

function Footer() {
  return (
    <footer className='Footer'>
      <div className="footer-nav">
        {businessInfo.logo 
          ? <img className="footer-logo" src={Logo} alt={`${businessInfo.name} Logo`} /> 
          : <h2 className='footer-brand has-text-primary has-text-weight-semibold'>{businessInfo.name}</h2>
        }
        <h2 className='footer-contact'>Questions? Contact us at <span className='has-text-primary has-text-weight-semibold'>{businessInfo.phone}</span></h2>
      </div>
      <p>Icons made by Eucalyp from www.flaticon.com.</p>
    </footer>
  )
}

export default Footer;