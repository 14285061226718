import React from 'react';
import EmailCapture from './EmailCapture';
// import '../styles/TheSell.scss';

function TheSell(props) {
  return (
    <section className='the-sell'>
      <EmailCapture setIsNotificationShowing={props.setIsNotificationShowing} />
    </section>
  )
}

export default TheSell;
